import React from "react"
//import loadable from '@loadable/component'
import Layout from "../components/Layout"
import { Container, Paper} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "64px",
    
  },
   outerIframe: {
    maxWidth: 770,
    maxHeight: 1200,
    overflow: "hidden",
    marginLeft: "auto ",
    marginRight: "auto ",
  },
  innerIframe: {
    position: "relative",
    height: 0,
    "-webkit-overflow-scrolling": "touch",
    overflow: "auto",
    paddingBottom: "330%",
    
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: "none",
  },


}))

/*export const pageQuery = graphql`
  query {
    headerImg: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`*/

export default function Contacto(props) {
  const classes = useStyles()

  return (
    <Layout>
      <Container className={classes.root} maxWidth="lg">
        <Paper style={{ background: "#f7b032" }}>
          <div className={classes.outerIframe}>
            <div className={classes.innerIframe}>
              <iframe
                id="programaFrame"
                title="programa"
                className={classes.iframe}
                src="https://docs.google.com/forms/d/e/1FAIpQLSeGq9xgGFCm6Svg2gG1JYBD5k5vndoy13NfeUcsq5JABoL2rA/viewform?embedded=true"
              ></iframe>
            </div>
          </div>
        </Paper>
      </Container>
    </Layout>
  )
}
